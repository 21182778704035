import { apiCall } from "@/setup/webApi";

export const getAll = (pageContext) => {
  const queryString = new URLSearchParams(pageContext).toString();

  return new Promise((resolve, reject) => {
    apiCall("get", `Tamreez/Donor/all?${queryString}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getById = (id) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Tamreez/Donor/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const add = (donor) => {
  return new Promise((resolve, reject) => {
    apiCall("post", `Tamreez/Donor/`, donor)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const update = (its, donor) => {
  return new Promise((resolve, reject) => {
    apiCall("put", `Tamreez/Donor/${its}`, donor)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteRecord = (its) => {
  return new Promise((resolve, reject) => {
    apiCall("delete", `Tamreez/Donor/${its}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const printCard = (its) => {
  return new Promise((resolve, reject) => {
    apiCall("get", `Tamreez/Donor/print/${its}`)
      .then((response) => {
        resolve(response);
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};