<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title>
                    Notice Board
                </v-card-title>
                <v-card-text>
                    <v-btn @click="dlgMarkAttendance.show()" :block="mobile">Mark Attendance</v-btn>

                    <!-- <v-card class="mt-3 text-center" @click="navigateToPage('survey-forms/11')" color="red" v-if="validateAuthOfficers || validateSeniors">
                        <v-card-text>
                            <h3>FOR SENIORS ONLY</h3>
                            <h2>Seniors Membership Request Form</h2>
                            <h3>Click Here To View</h3>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3 text-center" @click="navigateToPage('video-library/1')" color="blue">
                        <v-card-text>
                            <h3>BGI Important Paighaam - 1446H Surat</h3>
                            <h3>Click Here To View</h3>
                        </v-card-text>
                    </v-card> -->

                    <!-- <v-card class="mt-3 text-center" style="cursor: pointer;" @click="navigateToPage('tabudaat-forms/2')">
                        <v-card-text>
                            <v-img :src="require(`@/assets/TabudaatAraz.jpg`)" v-if="mobile" />
                            <v-img :src="require(`@/assets/TabudaatAraz.jpg`)" v-else height="200px" />
                            <h3>Click here to submit details</h3>
                        </v-card-text>
                    </v-card> -->

                    <!-- <v-alert type="info" icon="mdi-information" title="Ashara Mubarakah 1446H - Khidmat Survey"
                        text="Click here to view Survey form." class="my-2" @click="navigateToPage('survey-forms/9')"
                        style="cursor: pointer;" /> -->

                    <!-- <v-alert type="info" icon="mdi-information" v-if="validateIncharges || validateAuthOfficers"
                        title="Divisions Inventory Survey" text="Click here to view Survey form." class="my-2"
                        @click="navigateToPage('survey-forms/5')" style="cursor: pointer;" />

                    <v-alert type="info" v-if="validateAuthOfficers || miqaatProcRights" icon="mdi-information"
                        title="Ashara Mubaraka 1446H Procurement Requirements" text="Click here to view the form."
                        class="my-2" @click="navigateToPage('miqaat/procurement-form')" /> -->

                    <!-- <v-alert type="info" icon="mdi-information"
                        title="Ashara Mubarakah 1446H" text="Click here to view Survey form." class="my-2"
                        @click="navigateToPage('survey-forms/8')" style="cursor: pointer;" /> -->
                    <!-- <v-alert type="info" icon="mdi-information"
                        title="Imaani School Survey" text="Click here to view Survey form." class="my-2"
                        @click="navigateToPage('survey-forms/7')" style="cursor: pointer;" /> -->
                    <!-- <v-alert type="info" v-if="validateAuthOfficers" icon="mdi-information" title="FMB Thali Survey Form" text="Click here to view FMB Survey form." class="my-2" @click="navigateToPage('survey-forms/4')" /> -->
                    <!-- <v-alert type="info" icon="mdi-information" title="Al-Istifada Al-Ilmiyah 1445H Survey Form" text="Click here to view Al-Istifada Al-Ilmiyah 1445H survey form." class="my-2" @click="navigateToPage('survey-forms/1')" />
                    <v-alert type="info" icon="mdi-information" title="Ashara Mubarakah 1446H Registration Form" text="Click here to view Ashara 1446H registration form." class="my-2" @click="navigateToPage('survey-forms/2')" /> -->
                    <!-- <v-alert type="info" v-if="validateAuthOfficers" icon="mdi-information" title="Upliftment Form" text="Click here to view member upliftment form." class="my-2" @click="navigateToPage('upliftment-form')" /> -->

                    <!-- <v-alert type="info" icon="mdi-information" title="Attendance Event" text="Click here to view attendance event for Urs Raat Syedna Mohammad Burhanuddin (RA)." class="my-2" @click="navigateToPage(`attendance/7565`)" /> -->
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-row v-if="validateAuthOfficers">
        <v-col md="4" cols="12">
            <v-card>
                <v-card-title>
                    Transfer Requests
                </v-card-title>
                <v-card-text>
                    <TransferApprovalView />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col md="4" cols="12">
            <v-card>
                <v-card-title>
                    Status Requests
                </v-card-title>
                <v-card-text>
                    <StatusApprovalView />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <simple-dialog ref="dlgMarkAttendance" title="Mark Attendance" width="100vw" :hideConfirmButton="true"
        cancelButtonText="Close">
        <template v-slot:content>
            <qr-code-scanner-view @scanned="scanned" />
        </template>
    </simple-dialog>
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue';
// import { useRouter } from 'vue-router';
import { useAuthStore } from "@/setup/stores/authStore";
import QrCodeScannerView from './Attendance/QrCodeScannerView.vue';
import TransferApprovalView from './Dashboard/TransferApprovalView.vue';
import StatusApprovalView from './Dashboard/StatusApprovalView.vue';
import { useDisplay } from 'vuetify'
import * as miqaatService from '@/services/miqaatService';

const { mobile } = useDisplay();
const authStore = useAuthStore();
// const router = useRouter();

const loader = inject("loader");
// const snackbar = inject("snackBar");

const authOfficers = ref([
    40496719,
    40476109,
    40419031,
    40480238,
    40452802,
    40480239,
    40496185,
    40463461,
]);

// const seniors = ref([
//     20342202,
//     20366455,
//     20366469,
//     20366494,
//     20368891,
//     20370588,
//     20370602,
//     20370615,
//     20377600,
//     20377698,
//     20380601,
//     20380757,
//     20381019,
//     20381629,
//     20383454,
//     20388069,
//     20388272,
//     20388930,
//     40418380,
//     40430737,
//     40434626,
//     40445426,
//     40447099,
//     40461511,
//     40463739,
//     40480917,
//     40485008,
//     40489014,
//     40498124,
//     40498164,
//     40498487,
//     50488538
// ]);

const dlgMarkAttendance = ref(null);

const activeMiqaat = ref(null);
const miqaatProcRights = ref(null);

const validateAuthOfficers = computed(() => {
    if (!!authStore.user && !!authStore.user.itsId)
        return authOfficers.value.includes(authStore.user.itsId);
    else
        return false;
});

// const validateSeniors = computed(() => {
//     if (!!authStore.user && !!authStore.user.itsId)
//         return seniors.value.includes(authStore.user.itsId);
//     else
//         return false;
// });

// eslint-disable-next-line
const validateIncharges = computed(() => {
    if (authStore.user)
        return authStore.user.isIncharge;
    else
        return false;
});

// function navigateToPage(page) {
//     router.push(page)
// }

function scanned() {
    dlgMarkAttendance.value.hide();
}

onMounted(async () => {

    loader.show();

    var promises = [await miqaatService.getActive()];

    const [miqaat] = await Promise.all(promises);

    activeMiqaat.value = miqaat;

    if (activeMiqaat.value) {
        miqaatProcRights.value = await miqaatService.procurement_getRights(activeMiqaat.value.miqaatId);
    }

    loader.hide();

});
</script>