<template>
    <v-app>
        <v-layout>
            <v-app-bar title="Al-Tamreez Blood Donation Management System">
                <v-chip color="primary" class="mr-3">1.0.0</v-chip>
                <v-btn @click="toggleTheme" class="d-none">toggle theme</v-btn>
            </v-app-bar>

            <v-main>
                <v-container fluid>
                    <v-card>
                        <v-card-text>
                            <v-form ref="formDonor" @submit.prevent="save">
                                <div class="d-flex">
                                    <v-text-field label="Its Id" class="w-50 mr-1" v-model="donor.itsId"
                                        :rules="rules.itsId" ref="txtItsId" />
                                    <v-text-field label="Full Name" class="w-50 ml-1" v-model="donor.fullName"
                                        :rules="rules.fullName" />
                                </div>
                                <div class="d-flex">
                                    <v-autocomplete label="Mohallah" :items="mohallahs" class="w-50 mr-1"
                                        v-model="donor.mohallahName" :rules="rules.mohallahName" />
                                    <v-text-field label="Mobile No" class="w-50 ml-1" v-model="donor.mobileNo"
                                        :rules="rules.mobileNo" />
                                </div>
                                <div class="d-flex">
                                    <v-autocomplete label="Blood Group" :items="bloodgroups" class="w-50 mr-1"
                                        v-model="donor.bloodGroup" :rules="rules.bloodGroup" />
                                    <v-text-field label="Email Address" class="w-50 ml-1"
                                        v-model="donor.emailAddress" />
                                </div>
                                <div class="d-flex">
                                    <v-text-field label="CNIC No" class="w-50 mr-1" v-model="donor.cnic"
                                        :rules="rules.cnic" />
                                    <v-text-field label="Last Donation Date" class="w-50 ml-1" type="date"
                                        v-model="donor.lastDonationDate" />
                                </div>
                                <v-row>
                                    <v-col class="d-flex">
                                        <v-spacer></v-spacer>
                                        <v-btn variant="text" color="error" @click="clear">Clear</v-btn>
                                        <v-btn class="ml-2" type="submit">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>

                    <data-table :tableData="tableData" class="mt-6" @pageChanged="pageChanged">
                        <template v-slot:[`item.Actions`]="{ item }">
                            <v-btn @click="viewCard(item.itsId)" size="small">View Card</v-btn>
                        </template>
                    </data-table>

                    <simple-dialog ref="dlgCard" title="Card" width="60vw" height="100vh" cancelButtonText="Close"
                        :hideConfirmButton="true">
                        <template v-slot:content>
                            <div ref="htmlContainer" style="width: 100%; height: 80vh; box-sizing: border-box;">
                                <iframe ref="cardFrame" frameborder="0"
                                    style="width: 100%; height: 80vh; box-sizing: border-box;"></iframe>
                            </div>
                        </template>
                    </simple-dialog>

                </v-container>
            </v-main>
        </v-layout>

        <snack-bar />
        <loading-dialog />
    </v-app>
</template>

<script setup>
import { reactive, ref, inject, onMounted, nextTick, onUnmounted } from 'vue';
import { useTheme } from 'vuetify'
import { useRouter } from "vue-router";
import * as donorService from '@/services/tamreezDonorService';

const theme = useTheme()
const router = useRouter();
const loader = inject("loader");
const snackbar = inject("snackBar");

const mohallahs = [
    'Adam Mohallah',
    'Taheri Mohallah',
    'Al-Mohallatul-Burhaniyah',
    'Yousufi Mohallah',
    'Ibrahim Mohallah',
    'Qutbi Mohallah',
    'Mohammedi Mohallah',
    'Al-Mohallatul-Mohammediyah',
    'Saleh Mohallah',
    'Essa Mohallah',
    'Hasani Mohallah',
    'Hussaini Mohallah',
    'Burhani Mohallah',
    'Saifee Mohallah',
    'Jamali Mohallah',
    'Al-Mohallatul-Fakhriyah',
    'Burhani Bagh Mohallah',
    'Ezzi Mohallah',
];

const bloodgroups = [
    'A Positive',
    'A Negative',
    'B Positive',
    'B Negative',
    'AB Positive',
    'AB Negative',
    'O Positive',
    'O Negative',
];

// const isEdit = ref(false);

const formDonor = ref(null);
const txtItsId = ref(null);
const dlgCard = ref(null);
const cardFrame = ref(null);

const htmlContent = ref(null);

const donor = ref({
    itsId: '',
    fullName: '',
    mohallahName: '',
    mobileNo: '',
    emailAddress: '',
    cnic: '',
    bloodGroup: '',
    lastDonationDate: '',
});

const pageContext = reactive({
    search: "",
    pageNumber: 1,
    pageSize: 15,
    // itsId: '',
    // fullName: '',
    // mobileNo: ''
});

const tableData = reactive({
    headers: [
        { title: "S. No", key: "SNo" },
        { title: "Its", key: "itsId" },
        { title: "Name", key: "fullName" },
        { title: "Mohallah", key: "mohallahName" },
        { title: "Blood", key: "bloodGroup" },
        { title: "Mobile", key: "mobileNo" },
        { title: "CNIC", key: "cnic" },
        { title: "Actions", key: "Actions" },
    ],
    data: [],
    itemsPerPage: 15,
    totalItems: 0,
    paginationContext: {},
});

const rules = {
    itsId: [
        value => {
            if (value) return true;
            return 'Its number is required';
        }
    ],
    fullName: [
        value => {
            if (value) return true;
            return 'Full name is required';
        }
    ],
    mohallahName: [
        value => {
            if (value) return true;
            return 'Mohallah name is required';
        }
    ],
    mobileNo: [
        value => {
            if (value) return true;
            return 'Mobile number is required';
        }
    ],
    bloodGroup: [
        value => {
            if (value) return true;
            return 'Blood group is required';
        }
    ],
    cnic: [
        value => {
            if (value) return true;
            return 'CNIC number is required';
        }
    ],
}

const deadline = new Date("2025-02-19T18:00:00"); // 19-Feb-2025 06:00 PM
let checkInterval = null;

async function getData() {
    loader.show();

    let result = await donorService.getAll(pageContext)
        .catch((ex) => {
            console.log(ex);
            snackbar.error(ex);
        });

    if (result) {
        tableData.data = result.items;
        tableData.paginationContext = {
            pageSize: pageContext.pageSize,
            currentPage: result.currentPage,
            totalCount: result.totalCount,
            totalPages: result.totalPages,
            hasNext: result.hasNext,
            hasPrevious: result.hasPrevious,
        };
    }

    loader.hide();
}

async function pageChanged(pageNo) {
    pageContext.pageNumber = pageNo;
    await getData();
}

function clear() {
    donor.value.itsId = '';
    donor.value.fullName = '';
    donor.value.mohallahName = '';
    donor.value.mobileNo = '';
    donor.value.emailAddress = '';
    donor.value.cnic = '';
    donor.value.bloodGroup = '';
    donor.value.lastDonationDate = '';

    formDonor.value.reset();
}

async function save() {
    const { valid } = await formDonor.value.validate();

    if (!valid)
        return;

    loader.show();

    await donorService.add(donor.value)
        .then(async () => {
            donor.value.itsId = '';
            donor.value.fullName = '';
            donor.value.mohallahName = '';
            donor.value.mobileNo = '';
            donor.value.emailAddress = '';
            donor.value.cnic = '';
            donor.value.bloodGroup = '';
            donor.value.lastDonationDate = '';

            formDonor.value.reset();

            await getData();
        })
        .catch((ex) => {
            console.log(ex);
            snackbar.error(ex);
        });

    txtItsId.value.focus();

    loader.hide();
}

async function viewCard(its) {
    htmlContent.value = null;

    loader.show();

    var result = await donorService.printCard(its)
        .catch((ex) => {
            console.log(ex);
            snackbar.error(ex);
        });

    // htmlContent.value = result;
    loader.hide();

    dlgCard.value.show();
    await nextTick();

    const doc = cardFrame.value.contentWindow.document;
    doc.open();
    doc.write(result);
    doc.close();
}

function toggleTheme() {
    theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
}

function checkTime() {
    const now = new Date();

    if (now >= deadline) {
        router.replace("/login"); // Redirect user
    }
}

onMounted(async () => {
    // theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';

    checkTime(); // Check immediately on page load

    // Check every second
    checkInterval = setInterval(() => {
        checkTime();
    }, 1000);

    await getData();
});

onUnmounted(() => {
    clearInterval(checkInterval); // Cleanup when leaving page
});

</script>